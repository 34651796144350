var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"wrapper-element",class:[
    _vm.wrapTop ? 'flex gap-x-5 gap-y-3 max-xs:flex-col' : 'space-y-3',
    _vm.listDivider ? 'list-divider border-theme' : '',
  ]},[(_vm.wrapTop)?_c('div',{staticClass:"shrink-0",class:_vm.large ? 'xs:!w-1/3 xl:!w-3/10' : 'xs:!w-1/4'},[_vm._t("top")],2):_vm._t("top"),_vm._v(" "),_c('div',{staticClass:"w-full",class:_vm.wrapTop ? `shrink${_vm.wrap ? '' : ' ellipsis'}` : ''},[_c('div',{staticClass:"flex justify-between",attrs:{"inert":_vm.isInert}},[(_vm.showLeft)?_c('div',{staticClass:"flex"},[_c('div',{class:[_vm.large ? 'mr-4' : 'mr-3', _vm.leftGapClasses]},[_vm._t("left")],2)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-full overflow-hidden",class:_vm.header ? 'flex flex-col justify-end' : ''},[(_vm.header)?_c('div',{staticClass:"uppercase",class:[_vm.metadataColor, _vm.micro ? 'text-xs' : 'text-sm']},[_vm._t("header")],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"-mt-0.5",class:[
            _vm.wrap ? '' : 'ellipsis',
            _vm.large ? 'sm:mt-0 text-lg' : _vm.micro ? 'text-sm !-mt-1' : '',
          ]},[_vm._t("line-1")],2),_vm._v(" "),_c('div',{class:[_vm.metadataColor, _vm.micro ? '-mt-0.5 text-xs' : 'text-sm']},[_vm._t("line-2")],2)]),_vm._v(" "),(_vm.stackedButtons || !_vm.removeButtons)?_c('div',{staticClass:"shrink-0 flex ml-4",class:[_vm.stackedButtons ? 'flex-col' : '']},[_c('div',{staticClass:"flex items-center justify-end space-x-3 xs:space-x-4"},[(!_vm.removeButtons)?_vm._t("buttons"):_vm._e()],2),_vm._v(" "),(_vm.stackedButtons)?_c('div',{staticClass:"max-sm:hidden"},[_vm._t("stacked-buttons")],2):_vm._e()]):_vm._e()]),_vm._v(" "),_vm._t("default")],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }