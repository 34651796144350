import { ApiClient, ApiPaginationResult } from '~/apiclient/apiclient'
import { SermonApi } from '~/apiclient/apisermons'

export interface DevotionalApi {
  content: string
  day: string
  month: string
  quote: string
  reference: string
  sermon: SermonApi
  type: string
}

export interface DailyReadingApi {
  month: number
  day: number
  familyBook1: string
  displayFamilyChapter1: string
  familyBook2: string
  displayFamilyChapter2: string
  privateBook1: string
  displayPrivateChapter1: string
  privateBook2: string
  displayPrivateChapter2: string
}

export interface DailyHistoryApi {
  month: number
  day: number
  event: string
}

export interface QuoteApi {
  quote: string
  author: string
}

export async function getMorning(
  this: ApiClient,
  date: Date
): Promise<DevotionalApi> {
  const { data } = await this.$axios.get(
    `node/spurgeon_devotional?month=${
      date.getMonth() + 1
    }&day_of_month=${date.getDate()}&type=am`
  )
  return data.results
}

export async function getEvening(
  this: ApiClient,
  date: Date
): Promise<DevotionalApi> {
  const { data } = await this.$axios.get(
    `node/spurgeon_devotional?month=${
      date.getMonth() + 1
    }&day_of_month=${date.getDate()}&type=pm`
  )
  return data.results
}

export async function getCheckbook(
  this: ApiClient,
  date: Date
): Promise<DevotionalApi> {
  const { data } = await this.$axios.get(
    `node/spurgeon_devotional?month=${
      date.getMonth() + 1
    }&day_of_month=${date.getDate()}&type=checkbook`
  )
  return data.results
}

export async function getReading(
  this: ApiClient,
  date: Date
): Promise<DailyReadingApi> {
  const { data } = await this.$axios.get(
    `node/discover/daily/reading?month=${
      date.getMonth() + 1
    }&day_of_month=${date.getDate()}`
  )
  return data
}

export async function getTodayHistory(
  this: ApiClient,
  date: Date
): Promise<ApiPaginationResult<DailyHistoryApi>> {
  const { data } = await this.$axios.get(
    `node/discover/daily/history?month=${
      date.getMonth() + 1
    }&day_of_month=${date.getDate()}&shuffle=true`
  )
  return data
}

export async function getQuote(this: ApiClient): Promise<QuoteApi> {
  const { data } = await this.$axios.get(`node/discover/daily/quote`)
  return data
}
