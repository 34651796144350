
import Vue from 'vue'

export default Vue.extend({
  name: 'CoreElementWrapper',
  props: {
    loading: {
      type: Boolean,
    },
    stackedButtons: {
      type: Boolean,
    },
    showLeft: {
      type: Boolean,
      default: true,
    },
    leftGapClasses: {
      type: String,
      default: '',
    },
    large: {
      type: Boolean,
    },
    micro: {
      type: Boolean,
    },
    wrap: {
      type: Boolean,
    },
    header: {
      type: Boolean,
    },
    metadataColor: {
      type: String,
      default: 'text-gray-metadata',
    },
    removeButtons: {
      type: Boolean,
    },
    isInert: {
      type: Boolean,
    },
    wrapTop: {
      type: Boolean,
    },
    listDivider: {
      type: Boolean,
    },
  },
})
