import {
  ApiClient,
  ApiPaginationParams,
  ApiPaginationResult,
} from '~/apiclient/apiclient'
import {
  BroadcasterApi,
  BroadcasterNoticesApi,
} from '~/apiclient/apibroadcasters'
import { SpeakerApi } from '~/apiclient/apispeakers'
import { SeriesApi } from '~/apiclient/apiseries'
import { SermonApi } from '~/apiclient/apisermons'
import { Login } from '~/models/users/login'
import { DisplayUserApi } from '~/models/usercomment'
import { WebcastApi } from '~/apiclient/apiwebcasts'
import { DisplayArticleApi } from '~/apiclient/apiarticles'

export interface UserApi {
  display_name: string
  user_id: number
  username: string
  display_location?: string
  profile_picture?: string
  date_added: number
}

export interface UserFollowedOptions extends ApiPaginationParams {
  searchKeyword: string
}

export enum UserFollowedSortOptions {
  Newest = 'newest',
  Alphabetical = 'alpha',
}

export interface UserFollowedOptionsAndSort extends UserFollowedOptions {
  sortBy: UserFollowedSortOptions
}

export async function logOutAllDevices(this: ApiClient): Promise<undefined> {
  return await this.$axios.post('site/users/logout_all')
}

/** Used to retrieve user data non-reactively. Only use this if you want to analyze user data without displaying it, otherwise use $auth.fetchUser(). */
export async function getUser(this: ApiClient): Promise<Record<string, any>> {
  const { data } = await this.$axios.get('site/users')
  return data
}

export async function getFollowedBroadcasters(
  this: ApiClient,
  params: Partial<UserFollowedOptionsAndSort> = {}
): Promise<ApiPaginationResult<BroadcasterApi>> {
  const { data } = await this.$axios.get('site/users/broadcasters', {
    params,
  })
  return data
}

export async function setFollowedBroadcaster(
  this: ApiClient,
  broadcasterID: string
): Promise<void> {
  await this.$axios.post(`site/broadcasters/${broadcasterID}/follow`)
}

export async function removeFollowedBroadcaster(
  this: ApiClient,
  broadcasterID: string
): Promise<void> {
  await this.$axios.delete(`site/broadcasters/${broadcasterID}/follow`)
}

export async function isFollowedBroadcaster(
  this: ApiClient,
  broadcasterID: string
): Promise<boolean> {
  try {
    const { data } = await this.$axios.get(
      `site/broadcasters/${broadcasterID}/followed`
    )
    return data
  } catch (_e) {
    return false
  }
}

export async function getFollowedSpeakers(
  this: ApiClient,
  params: Partial<UserFollowedOptionsAndSort> = {}
): Promise<ApiPaginationResult<SpeakerApi>> {
  const { data } = await this.$axios.get('site/users/speakers', {
    params,
  })
  return data
}

function speakerUrl(speakerID: number, url: string) {
  return `site/speakers/${encodeURIComponent(speakerID)}/${url}`
}

export async function setFollowedSpeaker(
  this: ApiClient,
  speakerID: number
): Promise<void> {
  await this.$axios.post(speakerUrl(speakerID, 'follow'))
}

export async function removeFollowedSpeaker(
  this: ApiClient,
  speakerID: number
): Promise<void> {
  await this.$axios.delete(speakerUrl(speakerID, 'follow'))
}

export async function isFollowedSpeaker(
  this: ApiClient,
  speakerID: number
): Promise<boolean> {
  try {
    const { data } = await this.$axios.get(speakerUrl(speakerID, 'followed'))
    return data
  } catch (_e) {
    return false
  }
}

export async function getFollowedSeries(
  this: ApiClient,
  params: Partial<UserFollowedOptionsAndSort> = {}
): Promise<ApiPaginationResult<SeriesApi>> {
  const { data } = await this.$axios.get('site/users/series', {
    params,
  })
  return data
}

export async function setFollowedSeries(
  this: ApiClient,
  seriesID: number
): Promise<void> {
  await this.$axios.post(`site/series/${seriesID}/follow`)
}

export async function removeFollowedSeries(
  this: ApiClient,
  seriesID: number
): Promise<void> {
  await this.$axios.delete(`site/series/${seriesID}/follow`)
}

export async function isFollowedSeries(
  this: ApiClient,
  seriesID: number
): Promise<boolean> {
  try {
    const { data } = await this.$axios.get(`site/series/${seriesID}/followed`)
    return data
  } catch (_e) {
    return false
  }
}

export async function getFavoriteSermons(
  this: ApiClient,
  params: Partial<UserFollowedOptions> = {}
): Promise<ApiPaginationResult<SermonApi>> {
  const { data } = await this.$axios.get('site/users/sermons', {
    params,
  })
  return data
}

export async function setFavoriteSermon(
  this: ApiClient,
  sermonID: string
): Promise<void> {
  await this.$axios.post(`site/sermons/${sermonID}/follow`)
}

export async function removeFavoriteSermon(
  this: ApiClient,
  sermonID: string
): Promise<void> {
  await this.$axios.delete(`site/sermons/${sermonID}/follow`)
}

export async function isFavoriteSermon(
  this: ApiClient,
  sermonID: string
): Promise<boolean> {
  try {
    const { data } = await this.$axios.get(`site/sermons/${sermonID}/followed`)
    return data
  } catch (_e) {
    return false
  }
}

export interface PlayHistoryApi {
  date_accessed: number
  history_id: string
  sermon_id: string
  sermon: SermonApi
  user_id: string
  play_position: number
}

export async function getPlayHistory(
  this: ApiClient,
  params: ApiPaginationParams
): Promise<ApiPaginationResult<PlayHistoryApi>> {
  const { data } = await this.$axios.get('site/history/sermons', {
    params,
  })
  return data
}

/** Result of getSermonPlayHistory which returns null parameters when un-played */
export interface SinglePlayHistoryApi {
  date_accessed: number | null
  history_id: string | null
  play_position: number | null
  sermon: null
  sermon_id: string
  user_id: string
}

export async function getSermonPlayHistory(
  this: ApiClient,
  sermonID: string
): Promise<SinglePlayHistoryApi> {
  const { data } = await this.$axios.get(`site/history/sermons/${sermonID}`)
  return data
}

export async function setSermonPlayHistory(
  this: ApiClient,
  sermonID: string,
  playPosition: number
): Promise<any> {
  return await this.$axios.post(`site/history/sermons/${sermonID}`, {
    play_position: playPosition,
  })
}

export async function deletePlayHistory(
  this: ApiClient,
  historyID?: string
): Promise<any> {
  const url = historyID ? `site/history/${historyID}` : 'site/history/sermons'
  return await this.$axios.$delete(url)
}

interface IsAvailableApi {
  broadcasterID?: string
  isAvailable: boolean
}

interface EmailIsAvailableApi extends IsAvailableApi {
  broadcasterID?: string
  invalidEmail: boolean
}

export async function isValidUsername(
  this: ApiClient,
  username: string
): Promise<boolean> {
  try {
    const { data } = await this.$axios.get('site/users/available', {
      params: { username },
    })
    return (data as IsAvailableApi).isAvailable
  } catch (_e) {
    return false
  }
}

export async function isValidEmail(
  this: ApiClient,
  email: string
): Promise<EmailIsAvailableApi> {
  const { data } = await this.$axios.get('site/users/available', {
    params: { email },
  })
  return data
}

export interface LegacyBroadcasterLoginApiKey {
  apiType: 'broadcaster'
  key: string
  type: 'api_key'
}

export interface LegacyBroadcasterUserApi {
  broadcasterID: string
  memberType: string
  role: 'full' | 'sermons'
  type: 'user'
}

export interface LegacyBroadcasterLoginApi {
  apiKey?: LegacyBroadcasterLoginApiKey
  isLinked?: boolean
  reason: string
  reasonCode: number
  type: 'login_result'
  user?: LegacyBroadcasterUserApi
  valid?: boolean
}

export async function legacyBroadcasterLogin(
  this: ApiClient,
  login: Login
): Promise<LegacyBroadcasterLoginApi> {
  const { data } = await this.$axios.post('node/users/login', {
    broadcasterID: login.username,
    password: login.password,
    generate: false,
  })
  return data
}

export async function getUserBroadcasters(
  this: ApiClient
): Promise<BroadcasterApi[]> {
  try {
    const { data } = await this.$axios.get('node/broadcasters', {
      params: { linkedOnly: true, lite: true, pageSize: 100 },
    })
    return data.results
  } catch {
    return []
  }
}

export async function findUser(
  this: ApiClient,
  usernameOrEmail: string
): Promise<DisplayUserApi | undefined> {
  const { data } = await this.$axios.get('site/users/search', {
    params: {
      username_or_email: usernameOrEmail,
    },
  })
  return data.length ? data[0] : undefined
}

export enum UserFeedSources {
  Series = 'series',
  Speaker = 'speaker',
  Broadcaster = 'broadcaster',
}

const order = ['series', 'speaker', 'broadcaster']
export function SortUserFeedSources(sources: UserFeedSources[]) {
  return sources.sort((a, b) => order.indexOf(a) - order.indexOf(b))
}

export function GetFeedSource(sources: UserFeedSources[]) {
  if (!sources.length) return UserFeedSources.Broadcaster
  return SortUserFeedSources(sources)[0]
}

export enum UserFeedItemType {
  Notice = 'broadcaster_notice',
  Webcast = 'webcast',
  Sermon = 'sermon',
  Article = 'article',
}

export interface UserFeedResultsApi {
  itemType: UserFeedItemType
  timestamp: number
  notice?: BroadcasterNoticesApi
  webcast?: WebcastApi
  sermon?: SermonApi
  article?: DisplayArticleApi
}

export interface UserFeedParams extends ApiPaginationParams {
  feedItemType: UserFeedItemType
  cacheUserToken: string
}

/** totalCount is not available on this API */
export async function getUserFeed(
  this: ApiClient,
  params: UserFeedParams
): Promise<ApiPaginationResult<UserFeedResultsApi>> {
  const { data } = await this.$axios.get('site/users/feeds', {
    params,
  })
  return data
}

export async function activateDevice(
  this: ApiClient,
  userCode: string
): Promise<void> {
  const { data } = await this.$axios.post('site/device/activate', {
    userCode,
  })
  return data
}
